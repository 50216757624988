<template>
  <div>
    <div><strong>Client : </strong>{{ datas.client }}</div><br>
    <div><strong>Date :</strong> {{ dateFormat(datas.created_at) }}</div><br>
    <div><strong>Total TTC :</strong> {{ moneyFormat(datas.reservation.montant) }}</div><br>

    <table class="table">
          <thead>
            <tr>
              <th>LIBELLE</th>
              <th>Qté</th>
              <th>P.U</th>
              <th>Coût TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="produit in produits" :key="produit.id">
              <td>{{ produit.libelle }}</td>
              <td>{{ produit.qte }}</td>
              <td>{{ moneyFormat(produit.prix_de_vente) }} </td>
              <td>{{  moneyFormat(parseFloat(produit.prix_de_vente) * parseInt(produit.qte)) }} </td>
            </tr>
          </tbody>
        </table>

    <!-- Bouton pour télécharger la facture en PDF -->
    <button @click="downloadPDF" class="btn btn-primary mt-3">
      Télécharger le devis
    </button>
  </div>

  <!-- Zone cachée pour le PDF -->
  <div hidden>
    <div id="factureContent" class="container-fluid">
      <!-- Informations de l'entreprise -->
      <div class="row">
        <div class="col-6">
          <div class=" d-flex justify-content-center"><img src="/img/icons/epharma.png" alt="" id="imglogo2epharma">
          </div>
          <p>Client</p>
          <p class="font-weight-bold">{{datas.client}}</p>
          <p>Téléphone</p>
          <p class="font-weight-bold">062000000</p>
          <p>Adresse</p>
          <p class="font-weight-bold">Centre-Ville, Libreville Gabon</p>
        </div>
        <div class="col-6 text-right">
          <p>Email</p>
          <p class="font-weight-bold">pharmacie.testrouteur@gmail.com</p>
          <p>Information</p>
          <p class="font-weight-bold">SING Post Epharma</p>
        </div>
      </div>

      <!-- En-tête de la facture -->
      <div class="invoice-header">
        <div class="text-center">
          <h3>Devis</h3>
          <p>
            <strong>N° {{ datas.id }}</strong>
          </p>
        </div>
        <div class="text-right">
          <p>Date: {{ dateFormat(datas.created_at) }}</p>
        </div>
      </div>

      <!-- Tableau des produits -->
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>LIBELLE</th>
              <th>Qté</th>
              <th>P.U</th>
              <th>Coût TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="produit in produits" :key="produit.id">
              <td>{{ produit.libelle }}</td>
              <td>{{ produit.qte }}</td>
              <td>{{ moneyFormat(produit.prix_de_vente) }} </td>
              <td>{{  moneyFormat(parseFloat(produit.prix_de_vente) * parseInt(produit.qte)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pied de page -->
      <div class="footer">
        <div class="left-footer">
          <p><strong>Signature et cachet de la pharmacie</strong></p>
        </div>
        <div class="right-footer">
          <p><strong>Total TTC :</strong> {{ moneyFormat(datas.reservation.montant) }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import html2pdf from 'html2pdf.js';

export default {
  inject: ['dialogRef'],
  data() {
    return {
      datas: this.dialogRef.data,
      baseUrl: this.$store.state.api_epharma_ventes,
      columns: [
        // {
        //     key: 'logo',
        //     label: 'LOGO',
        //     exportable: true
        // },
        {
          key: 'libelle',
          label: 'PRODUIT',
          exportable: true
        },
        {
          key: 'qte',
          label: 'QUANTITE',
          exportable: true
        },
        {
          key: 'cout_total',
          label: 'COÛT',
          format: 'money',
          exportable: true
        },
        {
          key: 'prise_en_charge',
          label: '% PRIS EN CHARGE',
          exportable: true
        },
      ],
      produits: []
    };
  },
  mounted() {
    this.getProduits()
  },
  methods: {
    getProduits(){
      this.isLoading = true
        this.axios.get(this.baseUrl + '/api/reservation_produits?reservation_id=' + this.datas.reservation.id).then(response => {
          this.isLoading = false
          this.produits = response.data.data
        }).catch(() => {
          this.isLoading = false
          this.$toast.add({
              severity: 'error',
              summary: 'Probleme de connexion',
              detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
              life: 5000
          });
        })
    },
    dateFormat(varDate) {
      if (varDate != '') {
        const newDate = new Date(varDate)
        if (newDate) return moment(newDate).format('DD/MM/YYYY')
      }
    },
    moneyFormat(varMoney) {
      if (varMoney) return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA'
    },
    downloadPDF() {
      const element = document.getElementById('factureContent');
      const opt = {
        margin: 1,
        filename: `Facture_${this.datas.id}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      html2pdf().set(opt).from(element).save();
    }
  }
};
</script>

<style>
/* Style pour le texte au-dessus du tableau */
.row p {
  margin-bottom: 4px;
  /* Diminue l'espace entre les paragraphes */
  line-height: 1.2;
  /* Diminue l'espacement entre les lignes */
}

/* Autres styles déjà présents */
.table th,
.table td {
  padding: 8px;
}

.table th {
  text-align: left;
}

/* Style pour l'en-tête et le pied de page de la facture */
.invoice-header {
  margin-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left-footer,
.right-footer {
  text-align: left;
  margin-top: 50px;
}

.right-footer {
  text-align: right;
}

.imglogo2epharma {
  margin-bottom: 20px;
  padding: 8px;
  display: flex;
}

.row {
  margin-bottom: 8px;
  padding: 8px;
}
</style>