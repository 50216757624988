<template>
  <div id="app">
    <AlertBanner
      v-if="showAlertBanner"
      :message="alertMessage"
      :showUpdateLink="true"
      @close="showAlertBanner = false"
    />
    <RouterView/>
    <ShowToast />
    <DynamicDialog />

  </div>
</template>

<script>
import AlertBanner from './layouts/AlertBanner.vue';
import packageJson from '../package.json'; // Utilisation d'import ES6


export default {
  components: {
    AlertBanner,
   
  },
  data() {
    return {
      showAlertBanner: false,
      alertMessage: '',
    };
  },
  mounted() {
    // this.checkForUpdates();
  },
  methods: {
    async checkForUpdates() {
      try {
        const response = await this.axios.get('http://127.0.0.1:8009/api/latest-version');
        const latestVersion = response.data.numero_version;
        const currentVersion = packageJson.version;

        if (latestVersion && latestVersion !== currentVersion) {
          this.showAlertBanner = true;
          this.alertMessage = `Une nouvelle version (${latestVersion}) est disponible.`;
        }
      } catch (error) {
        console.error('Erreur lors de la vérification des mises à jour :', error);
      }
    },
  },
};
</script>



















<style lang="scss">
  @import './style/app.scss';
</style>
