import { createStore } from 'vuex'
import router from '../router'

interface User {
  id: Number,
  photo: String,
  name: String,
  fullname: String,
  email: String,
  role: String,
  must_change_password: String,
  adresse: String,
  boite_postale: String,
  ville: String,
  date_naissance: Date,
  sexe: String,
  poste: String,
  telephone: String,
  is_enabled: Boolean,
  is_admin: Boolean,
}

interface State {
  appIsLoading: Boolean,
  authenticated: Boolean,
  caisseAuthenticated: Boolean,
  caisseConnected: String,
  api_epharma_comptes: String;
  api_epharma_stock: String;
  api_epharma_ventes: String;
  api_epharma_update: String;
  user: User[];
}

export default createStore({
  state: {
    appIsLoading: false,
    authenticated: false,
    api_epharma_comptes: 'https://epharma-comptes.yamslogistics.com',
    api_epharma_stock: 'https://epharma-stock.yamslogistics.com',
    api_epharma_ventes: 'https://epharma-ventes.yamslogistics.com',
    api_epharma_update: '',
    caisseAuthenticated: false,
    caisseConnected: '',
    // api_epharma_comptes: 'http://localhost:8000',
    // api_epharma_stock: 'http://localhost:8001',
    // api_epharma_ventes: 'http://localhost:8002',
    // api_epharma_update: 'http://localhost:8009',
    user: [],
  } as State,
  getters: {
    appIsLoading: (state: State) => state.appIsLoading,
    authenticated: (state: State) => state.authenticated,
    user: (state: State) => state.user,
    caisseAuthenticated: (state: State) => state.caisseAuthenticated,
    caisseConnected: (state: State) => state.caisseConnected,
    apiComptes: (state: State) => state.api_epharma_comptes ,
    apiStock: (state: State) => state.api_epharma_stock ,
    apiVentes: (state: State) => state.api_epharma_ventes ,
    apiUpdate: (state: State) => state.api_epharma_update ,
  },
  mutations: {
    setAuthenticated(state: State, value: User[])  {
      state.appIsLoading = true
      state.authenticated = true
      state.user = value
      window.localStorage.setItem('epharma_asdecode_session', JSON.stringify(value))
      state.appIsLoading = false
    },
    setAuthenticatedCaisse(state: State, value: String)  {
      state.caisseAuthenticated = true
      state.caisseConnected = value
    },
    clearAuthenticatedCaisse(state: State)  {
      state.caisseAuthenticated = false
      state.caisseConnected = ''
    },
    setIsLoading(state: State, value: Boolean) {
      state.appIsLoading = value
    },
    clearUserData(state: State) {
      state.appIsLoading = true
      state.authenticated = false
      state.user = []
      window.localStorage.removeItem('epharma_asdecode_session')
      state.appIsLoading = false
      router.push({name: 'login'})
    },
    setUserProfileDatas(state: State, value: User[]) {
      state.appIsLoading = true
      state.user = value,
      window.localStorage.setItem('epharma_asdecode_session', JSON.stringify(value))
      state.appIsLoading = false
    },
  },
  actions: {
  },
  modules: {
  }
})
