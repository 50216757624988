import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from './../store'
import IndexAdmin from '@/views/admin/IndexAdmin.vue'
import IndexStock from '@/views/stock/IndexStock.vue'
import IndexVente from '@/views/vente/IndexVente.vue'
import IndexGestion from '@/views/gestion/IndexGestion.vue'
import LoginView from '@/views/auth/LoginView.vue'
import NewPasswordView from '@/views/auth/NewPasswordView.vue'
import IndexUtilisateur from '@/views/admin/utilisateurs/IndexUtilisateur.vue'
import IndexProduit from '@/views/stock/produits/IndexProduit.vue'
import IndexCommande from '@/views/stock/commandes/IndexCommande.vue'
import IndexFournisseurs from '@/views/stock/fournisseurs/IndexFournisseur.vue'
import IndexBdd from '@/views/stock/bases_de_donnees/IndexBdd.vue'
import IndexRayon from '@/views/stock/rayons/IndexRayon.vue'
import IndexMouvement from '@/views/stock/mouvement/IndexMouvement.vue'
import IndexCaisse from '@/views/vente/caisse/IndexCaisse.vue'
import IndexClient from '@/views/vente/client/IndexClient.vue'
import IndexDevis from '@/views/vente/devis/IndexDevis.vue'
import IndexDashboard from '@/views/vente/ventes/IndexDashboard.vue'
import IndexConfiguration from '@/views/vente/configuration/IndexConfiguration.vue'
import IndexBilletage from '@/views/gestion/billetages/IndexBilletage.vue'
import IndexJournees from '@/views/gestion/journees/IndexJournees.vue'
import IndexGarde from '@/views/gestion/gardes/IndexGarde.vue'
import IndexInventaire from '@/views/gestion/inventaires/IndexInventaire.vue'
import IndexParametre from '@/views/admin/parametres/IndexParametre.vue'
import IndexReservation from '@/views/vente/reservation/IndexReservation.vue'
import BilletageCaisse from '@/views/vente/caisse/BilletageCaisse.vue'
import IndexStatistique from '@/views/gestion/statistiques/IndexStatistique.vue'
import ShowReservationCaisse from '@/views/vente/caisse/ShowReservationCaisse.vue'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'home',
    component: IndexAdmin
  },
    {
    path: '/admin',
    name: 'admin',
    component: IndexAdmin
  },
  {
    path: '/stock',
    name: 'stock',
    component: IndexStock
  },
  {
    path: '/ventes',
    name: 'vente',
    component: IndexVente
  },
  {
    path: '/gestion',
    name: 'gestion',
    component: IndexGestion
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/new-password',
    name: 'new.password',
    component: NewPasswordView
  },
  {
    path: '/admin/utilisateurs',
    name: 'utilisateurs',
    component: IndexUtilisateur
  },

  {
    path: '/stock/produits',
    name: 'produit',
    component: IndexProduit
  },
   {
    path: '/stock/commandes',
    name:'commande',
    component:IndexCommande
  },
  {
    path : '/stock/fournisseurs',
    name:'fournisseurs',
    component:IndexFournisseurs
  },
  {
    path:'/stock/database',
    name:'database',
    component:IndexBdd
  },
  {
    path:'/stock/rayon',
    name:'rayon',
    component:IndexRayon
  },
  {
    path:'/stock/mouvement',
    name:'mouvement',
    component:IndexMouvement
  },
  {
    path:'/vente/caisses',
    name:'caisse',
    component:IndexCaisse
  },
  {
    path:'/vente/caisses/reservation/:id',
    name:'caisse.reservation',
    props: true,
    component:ShowReservationCaisse
  },
  {
    path:'/vente/clients',
    name:'client',
    component:IndexClient
  },
  {
    path:'/vente/devis',
    name:'devis',
    component:IndexDevis
  },
  {
    path:'/vente/dashboard',
    name:'dashboard',
    component:IndexDashboard
  },
  {
    path:'/vente/configuration',
    name:'configuration',
    component:IndexConfiguration
  },
  {
    path:'/gestion/arrete-caisse',
    name:'arrete-caisse',
    component:IndexBilletage
  },
  {
    path:'/gestion/fin-journee',
    name:'fin-journee',
    component:IndexJournees
  },
  {
    path:'/gestion/garde',
    name:'garde',
    component:IndexGarde
  },
  {
    path:'/gestion/inventaire',
    name:'inventaire',
    component:IndexInventaire
   },
  {
    path:'/admin/parametres',
    name:'parametres',
    component:IndexParametre
  },
  {
    path:'/vente/reservation',
    name:'reservation',
    component:IndexReservation
  },
  {
    path:'/numero',
    name:'numero',
    component:BilletageCaisse
  },
  {
    path:'/gestion/statistique',
    name:'statistique',
    component:IndexStatistique
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.name !== 'login' && !store.getters.authenticated) next({ name: 'login' })
  else if (to.name !== 'new.password' && store.getters.user.must_change_password == '1') next({ name: 'new.password' })
  else next()
})

export default router
