<template>
    <button class="btn btn-light" type="button" @click="getProducts()">
        <i class="fa-solid fa-download mr-1"></i>
        <span class="mr-1">Exporter</span>
        <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>

    <div hidden>
        <div :id="this.table + '_export'" class="card w-100">
            <div class="card-header">
                <h5 class="card-title">Tableau des {{ this.table }}</h5>
            </div>
            <div class="card-body p-0">
                <DataTable class="p-datatable-sm" :value="this.datas">
                    <DataTableColumn v-for="field in this.fields" :key="field" :field="field.key" :header="field.label">
                    </DataTableColumn>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    name: 'ExportTable',
    data() {
        return {
            baseUrl: this.$store.state.api_epharma_stock,
            table: 'produits',
            data: [],
            isLoading: false
        }
    },
    methods: {
        getProducts() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table).then(response => {
                this.isLoading = false
                this.data = response.data.data
                this.exportToExcel()
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        exportToExcel() {
            this.data
            const worksheet = XLSX.utils.json_to_sheet(this.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Produits');

            // Générer un fichier Excel
            XLSX.writeFile(workbook, 'produits.xlsx');
        }
    },
    // mounted() {
    //     this.getProducts();
    // }
}
</script>