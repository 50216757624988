<template>
    <EpharmaTemplate>
        <template #sous_menu>
            <NavStock></NavStock>
        </template>
        <template #page_title> MOUVEMENTS </template>
        <template #page_counter>
            <i class="fas fa-skating"></i>
        </template>
        <template #content>
            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
            <form @submit.prevent="mouvementFilter" class="border shadow-lg rounded p-3 mx-auto" style="opacity:">
                <div class="form-row">
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par motif</small>
                            <div>
                                <label></label>
                              <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.motif">
                               <option v-for="(motif, index) in motifList" :key="index" :value="motif">{{ motif }}</option>
                              </select>
                           </div>
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                               <small class="font-weight-bold pl-3">Rechercher par date</small>
                                <div>
                                    <label></label>
                                    <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                               </div>   
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                               <small class="font-weight-bold pl-3"></small>
                                <div>
                                    <label></label>
                                    <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                               </div>   
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par type</small>
                            <div>
                                <label></label>
                               <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.type">
                                <option value="Entrée">Entrée</option>
                                <option value="Sortie">Sortie</option>
                               </select>
                            </div>  
                        </div>
                    </div>
                    <div class="col-lg-4 mt-5">
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                title="Appliquer le filtrage"></i>
                        </button>
                        <button type="button" class="btn" @click="removeFilter()">
                            <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                title="Annuler le filtrage"></i>
                        </button>
                    </div>
                </div>
            </form><br>
            <AsdecodeTable :key="key" :newKeyWord="keyWord" :url="baseUrl + url" :columns="columns" :table="table"
                :v-on:set-total-records="updateLines">
                <template #header_action_btns>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import NavStock from "../NavStock";
import { motif } from '../produits/resources/motif.js'
import AsdecodeTable from "../../../components/AsdecodeTable.vue"
export default {
    components: { NavStock },
    data() {
        return {
            table: 'mouvements',
            baseUrl: this.$store.state.api_epharma_stock,
            defaultUrl: '/api/mouvements',
            url: '/api/mouvements',
            columns: [
                {
                    key: 'produit.libelle',
                    label: 'PRODUIT',
                    exportable: true
                },
                {
                    key: 'type',
                    label: 'CATEGORIE',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE',
                    exportable: true
                },
                {
                    key: 'created_at',
                    label: 'Date',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'motif',
                    label: 'MOTIF',
                    exportable: true
                },
                {
                    key: 'created_by',
                    label: 'UTILISATEUR',
                    exportable: true
                },
               
            ],
            key: 1,
            keyWord: '',
            filterForm: {},
        }
    },
    created() {
        this.motifList = motif;

    },
    methods: {
        checkUrlFormat(link){
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        mouvementFilter(){
            let newUrl = this.defaultUrl

            if (this.filterForm.motif) {
                newUrl = this.checkUrlFormat(newUrl) + 'motif=' + this.filterForm.motif
            }

            if (this.filterForm.type) {
                newUrl = this.checkUrlFormat(newUrl) + 'type=' + this.filterForm.type
            }

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut='+this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) +'from_period_fin='+this.filterForm.fin
            }

            this.url = newUrl
            this.key++
        },
        removeFilter() {
            this.url = this.defaultUrl
            this.key++
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    }

}
</script>