<template>
    <EpharmaTemplate>
          <template #sous_menu>
                <NavVente></NavVente>
          </template>
          <template #content>
                <DefaultView></DefaultView>
          </template>
    </EpharmaTemplate>
</template>

<script>
import NavVente from "./NavVente.vue";
import DefaultView from "@/components/DefaultView";
export default {
    components: { NavVente, DefaultView }
}
</script>