<template>
    <EpharmaTemplate>
        <template #sous_menu>
            <NavVente></NavVente>
        </template>
        <template #content>
            <ProgressBar class="my-1" v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
            <div class="bg-gris1">
                <div class="container">
                    <div class=" pt-3 ">
                        <div class="d-flex mx-auto align-items-center justify-content-between">
                            <div class="d-flex">
                                <button type="button" class="btn bg-black text-white">
                                    <h2>
                                        N° <span v-if="reservationSelected != null" class="h2">{{ reservationSelected.id }}</span>
                                    </h2>

                                </button>

                            </div>

                            <div class="d-flex " style="max-width: 100vh; overflow-x: auto;">
                                <button type="button" class="btn rounded-circle mx-1"
                                    v-for="(reservation, index) in reservations" :key="index"
                                    :class="[reservationSelected != null && reservationSelected.id == reservation.id ? 'bg-white border-primary' :'bg-white border-light']" @click="select_reservation(reservation)">
                                    {{ reservation.id }}
                                </button>
                            </div>

                            <div class="d-flex ">
                                <button type="button" class="btn" @click="getReservations()">
                                    <i class="fa-solid fa-rotate text-black bg-white p-2 rounded-circle"
                                        title="Rafraichir" style="font-size:25px"></i>
                                </button>

                                <button type="button" class="btn mr-4" style="width: 50px; max-width: 50px;"
                                    @click="logout()">
                                    <i class="fa-solid fa-right-to-bracket text-red1" title="Se déconnecter "
                                        style="font-size:25px"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="row" v-if="isLoading === false && reservationSelected">
                        <div class="form col-md-8">
                            <div class="card bg-white ">
                                <div class="border shadow-lg rounded">
                                    <div class="bg-bleuN m-3" v-if="caisse != null">
                                        <h6 class="text-center p-4">{{ caisse.libelle }}</h6>
                                    </div>
                                    <div class="form col-4 mb-4">
                                        <!-- <small class="font-weight-bold ">Clients</small> -->
                                        <div class="">
                                            <label>Client</label>
                                            <input type="text" :value="reservationSelected.client" class="form-control "
                                                style=" border-bottom: 1px solid black ; 
                                        border-left: none; border-right: none; border-top: none; 
                                    background: #fafafa;" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card body-bg-white mt-3 ">
                                <div class="border shadow-lg rounded">
                                    <AsdecodeTable :key="key" noExport
                                        :url="baseUrl + '/api/reservation_produits?reservation_id=' + reservationSelected.id"
                                        :columns="columns" table="reservation_produits">
                                    </AsdecodeTable>
                                </div>
                            </div>
                        </div>

                        <form class="form col-md-4" @submit.prevent="updateReservation">
                            <div class="d-flex justify-content-between">
                                <button type="submit" class="btn btn-black">
                                    Payer
                                </button>

                                <!-- <button type="button" class="btn btn-black" @click="openBilletage()">
                                    Billetage
                                </button> -->
                            </div>
                            <div class="bg-black rounded-sm mt-3 p-2  ">
                                <label class="text-white "> TTC</label>
                                <h5 class="text-white">{{ moneyFormat(reservationSelected.montant) }}</h5>
                            </div>
                            <div class="bg-black mt-3 rounded-sm p-3">
                                <div class="form-group">
                                    <label class="text-gri3">Montant reçu</label>
                                    <input type="number" v-model="form.amount_gived" class="form-control" required>
                                </div>
                                <div class="form-group" v-if="form.amount_gived">
                                    <label class="text-gri3">Difference</label>
                                    <input type="number"
                                        :value="parseFloat(form.amount_gived) - parseFloat(reservationSelected.montant)"
                                        class="form-control" disabled>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
const EditBilletage = defineAsyncComponent(() => import('../../gestion/billetages/EditBilletage.vue'));

export default defineComponent({
    data() {
        return {
            key: 1,
            table: 'reservations',
            baseUrl: this.$store.state.api_epharma_ventes,
            isLoading: false,
            reservationSelected: null,
            form: {
                status: 'Terminee'
            },
            // reservation: {},
            reservations: [],
            caisse: null,
            columns: [
                {
                    key: 'libelle',
                    label: 'PRODUIT',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE',
                    exportable: true
                },
                {
                    key: 'cout_total',
                    label: 'COÛT',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'prise_en_charge',
                    label: '% PRIS EN CHARGE',
                    exportable: true
                },
            ]

        }

    },
    beforeCreate() {
        if (this.$store.getters.caisseConnected != this.$route.params.id || !this.$store.getters.caisseAuthenticated) {
            this.$router.push({ name: 'caisse' })
        }
    },
    created() {
        this.getCaisse()
    },
    mounted() {
        this.getReservations()
    },
    methods: {
        logout() {
            this.$store.commit('clearAuthenticatedCaisse')
            this.$router.push({ name: 'caisse' })
        },
        openBilletage() {
            this.$dialog.open(EditBilletage, {
                props: {
                    header: "Enregistrement du billetage de la caisse " + this.caisse.libelle,
                    style: {
                        width: '80vw',
                    },
                    modal: true
                },
                data: this.reservationSelected
            });
        },
        moneyFormat(varMoney) {
            if (varMoney) return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA'
        },
        getCaisse() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/caisses/' + this.$route.params.id).then((response) => {
                this.caisse = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getReservations() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservations' + '?caisse_id=' + this.$route.params.id).then((response) => {
                // Tri les réservations par id du plus grand au plus petit
                this.reservations = response.data.data
                // .sort((a, b) => b.id - a.id)
                // .map((reservation, index) => {
                //     // On ajoute la clé "position" en commençant par 1
                //     return {
                //         ...reservation,
                //         position: index + 1,
                //     };
                // });
                // Sélectionne la réservation avec la dernière position (la première du tableau trié)
                if (this.reservations.length > 0) {
                    this.reservationSelected = this.reservations[this.reservations.length - 1];
                }
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        select_reservation(reservation) {
            this.reservationSelected = reservation
            this.key++
            this.resetForm()
        },
        resetForm() {
            this.form = {
                status: 'Terminee'
            }
        },
        async updateReservation() {
            if ((parseFloat(this.form.amount_gived) - parseFloat(this.reservationSelected.montant)) < 0) {
                alert('Le difference ne peut etre negative');
                return
            }

            await this.axios.post(`${this.baseUrl}/api/reservations/${this.reservationSelected.id}/update`, this.form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        }


    }

})
</script>