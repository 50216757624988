<template>
    <EpharmaTemplate>
        <template #sous_menu>
            <NavVente></NavVente>
        </template>
        <template #content>
            <div class="bg-gris1">
                <div class="container">
                    <div class=" pt-3">
                        <div class="d-flex mx-auto align-items-center justify-content-between">
                            <div class="d-flex">
                                <button type="button" class="btn bg-bleureservation text-white" style="font-size:30px">
                                    <h2>
                                        N° <span v-if="reservationSelected != null" class="h2">{{ reservationSelected.id
                                            }}</span>
                                    </h2>
                                </button>
                                <button type="submit" class="btn mx-4" style="width: 50px; max-width: 50px;"
                                    @click="createTable">
                                    <i class="fas fa-plus-circle text-bleureservation bg-white p-2 rounded-circle"
                                        title="Nouvelle réservation" style="font-size:25px"></i>
                                </button>
                            </div>
                            <div class="d-flex justify-content-start" style="max-width: 100vh; overflow-x: auto;">
                                <button type="button" class="btn  rounded-pill mx-1"
                                    v-for="(reservation, index) in reservations" :key="index"
                                    :class="[reservationSelected != null && reservationSelected.id == reservation.id ? 'bg-white border-primary' :'bg-white border-light']"
                                     @click="select_reservation(reservation)">
                                     <span :class="{'text-danger' : reservation.status == 'Annulee'}">
                                        {{ reservation.id }}
                                     </span>
                                    
                                </button>
                            </div>

                            <div class="d-flex">
                                <button
                                    v-if="form.status != 'Terminee' && form.status != 'Annulee' && form.status != 'Devis'"
                                    type="button" class="btn mr-4" style="width: 50px; max-width: 50px;"
                                    @click="stopreservation">
                                    <i class="fas fa-minus-circle text-danger bg-white p-2 rounded-circle"
                                        title="Annuler la réservation" style="font-size:25px"></i>
                                </button>
                                <button type="button" class="btn" @click="getReservations()">
                                    <i class="fa-solid fa-rotate text-bleureservation bg-white p-2 rounded-circle"
                                        title="Rafraichir" style="font-size:25px"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div class="my-3" v-if="reservationSelected != null">
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-8">

                                <div class="card card-body mb-3 position-relative">
                                    <!-- Filigrane  -->
                                    <div id="overlay" class="overlay "
                                        v-if="form.status == 'Annulee' || form.status == 'Devis' || form.status == 'Terminee'">
                                    </div>
                                    <div class="watermark border-danger px-5" v-if="form.status == 'Annulee'">
                                        Reservation
                                        annulée
                                    </div>
                                    <div class="watermark border-danger px-5" v-else-if="form.status == 'Devis'">
                                        Reservation fermée</div>
                                    <div class="watermark border-danger px-5" v-else-if="form.status == 'Devis'">
                                        Transformée en devis</div>

                                    <div class="d-flex flex-wrap mb-3">
                                        <button type="button" v-for="caisse in caisses" :key="caisse.id"
                                            :class="{ 'border border-primary': this.form.caisse_id == caisse.id }"
                                            class="btn btn-light mr-2 mb-2 py-3 px-4 text-dark"
                                            @click="addCaisseId(caisse)">
                                            <i class="fas fa-cash-register mr-1"></i>
                                            <span>{{ caisse.libelle }}</span>
                                        </button>
                                    </div>

                                    <form>
                                        <div class="form-row">
                                            <div class="form-group col-md-5">
                                                <label>Client</label>
                                                <select class="form-control" v-model="form.client_id"
                                                    @change="setClientLibelle(true)">
                                                    <option :value="client.id" v-for="client in clients"
                                                        :key="client.id">
                                                        <span v-if="client.client_id">{{ client.nom }} ({{
                                                            client.libelle }})</span>
                                                        <span v-else>{{ client.libelle }}</span>
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-row"
                                            v-if="clientSelected != '' && clientSelected.libelle && clientSelected.libelle != 'COMPTANT'">
                                            <div class="form-group col-md-5">
                                                <label>Identifiant de l' (NAG)</label>
                                                <input type="text" v-model="form.identifiant_assure"
                                                    class="form-control" @change="updateReservation()">
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Nom de l'assuré</label>
                                                <input type="text" v-model="form.nom_assure" class="form-control"
                                                    @change="updateReservation()">
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>N° Feuille Assuré</label>
                                                <input type="text" v-model="form.numero_feuille_assure"
                                                    class="form-control" @change="updateReservation()">
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Secteur</label>
                                                <select v-model="form.secteur_assure" class="form-control"
                                                    @change="updateReservation()">
                                                    <option>Secteur public</option>
                                                    <option>Secteur privé</option>
                                                    <option>GEF</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <AsdecodeTable :key="key" noExport
                                    :url="baseUrl + '/api/reservation_produits?reservation_id=' + reservationSelected.id"
                                    :columns="columns" table="reservation_produits">
                                    <template #header_action_btns
                                        v-if="form.status != 'Terminee' && form.status != 'Annulee' && form.status != 'Devis'">
                                        <button type="button" class="btn btn-light mr-2"
                                            @click="openSelectReservationProduit()">
                                            <i class="fa-solid fa-plus"></i> Ajouter des produits
                                        </button>
                                        <button type="button" class="btn btn-light mr-2" @click="switchToDevis()">
                                            <i class="fa-solid fa-file-pdf"></i> Devis
                                        </button>
                                    </template>
                                    <template #action_btns=slotProps
                                        v-if="form.status != 'Terminee' && form.status != 'Annulee' && form.status != 'Devis'">
                                        <button type="button" class="btn btn-sm text-danger"
                                            @click="deleteLine(slotProps.data.id)">
                                            <i class="fa-solid fa-trash-alt"></i>
                                        </button>
                                    </template>

                                    <template #qte=slotProps>
                                        <input type="number" style="width: 90px;" class="form-control" name="qte"
                                            :value="slotProps.data.qte" :max="slotProps.data.produit.qte"
                                            @change="updateReservationProduit(slotProps.data, $event)"
                                            :disabled="form.status == 'Annulee' || form.status == 'Terminee' || form.status == 'Devis'">
                                    </template>
                                    <template #prise_en_charge=slotProps>
                                        <input v-if="slotProps.data.prise_en_charge" type="number" style="width: 90px;" class="form-control" name="prise_en_charge"
                                            :value="parseFloat(slotProps.data.prise_en_charge)" min="0" step="any"
                                            @change="updateReservationProduit(slotProps.data, $event)"
                                            :disabled="form.status == 'Annulee' || form.status == 'Terminee' || form.status == 'Devis'">
                                        <input v-else type="number" style="width: 90px;" class="form-control" name="prise_en_charge"
                                            :value="parseFloat(clientSelected.remise_percent)" min="0" step="any"
                                            @change="updateReservationProduit(slotProps.data, $event)"
                                            :disabled="form.status == 'Annulee' || form.status == 'Terminee' || form.status == 'Devis'">
                                    </template>
                                </AsdecodeTable>
                            </div>
                            <div class="col-md-2">
                                <button hidden id="refreshreservationmontant" type="button"
                                    @click="refreshReservation()">Raffraichir</button>
                                <div class="card card-body p-1 bg-bleureservation text-white">
                                    <h4>TTC</h4>
                                    <span v-if="refreshIsLoading == true" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <h3 v-else>{{ moneyFormat(form.montant) }}</h3>
                                </div>

                                <div
                                    v-if="reservationSelected != null && form.client_id != null && form.status != 'Terminee' && form.status != 'Annulee' && form.status != 'Devis' && form.caisse != null">
                                    <button type="button" class="btn btn-primary mx-auto mt-5"
                                        @click="closeReservation()">
                                        <i class="fa-solid fa-lock"></i> Passer à la caisse
                                    </button>
                                </div>
                                <div class="mt-5" v-else-if="reservationSelected != null && form.status == 'Terminee'">
                                    <p>Réservation envoyé à la caisse {{ form.caisse }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import NavVente from "../NavVente.vue";
import $ from 'jquery'
const SelectReservationProduit = defineAsyncComponent(() => import('./SelectReservationProduit.vue'));

export default defineComponent({
    components: { NavVente },
    data() {
        return {
            key: 1,
            table: 'reservations',
            isLoading: false,
            refreshIsLoading: false,
            caisses: [],
            clients: [],
            clientSelected: '',
            reservations: [],
            reservationSelected: null,
            baseUrl: this.$store.state.api_epharma_ventes,
            selectedProduits: null,
            produit: {},
            form: {},
            reservation: {},
            showBackground: false,
            montantToutTaxe: 0,
            columns: [
                // {
                //     key: 'logo',
                //     label: 'LOGO',
                //     exportable: true
                // },
                {
                    key: 'libelle',
                    label: 'PRODUIT',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'cout_total',
                    label: 'COÛT',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'prise_en_charge',
                    label: '% PRIS EN CHARGE',
                    format: 'slot',
                    exportable: true
                },
            ]
        }
    },
    mounted() {
        this.getCaisses()
        this.getClients()
        this.getReservations()
    },
    methods: {
        setClientLibelle(withUpdate) {
            let client = this.clients.filter(item => item.id == this.form.client_id)
            if (client.length > 0) {
                this.clientSelected = client[0]
                this.form.client = client[0].nom
            }
            if (withUpdate == true) this.updateReservation()
        },
        moneyFormat(varMoney) {
            if (varMoney) return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA'
        },
        openSelectReservationProduit() {
            this.$dialog.open(SelectReservationProduit, {
                props: {
                    header: "Selectionner les produits",
                    style: {
                        width: '80vw',
                    },
                    modal: true
                },
                data: this.reservationSelected
            });
        },
        getCaisses() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/caisses?statut=Ouvert').then(response => {
                this.isLoading = false
                this.caisses = response.data.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        refreshReservation() {
            this.refreshIsLoading = true
            this.axios.get(this.baseUrl + '/api/reservations/' + this.reservationSelected.id).then((response) => {
                const reservation = response.data.data
                this.form.montant = reservation.montant
                this.refreshIsLoading = false
            }).catch(() => {
                this.refreshIsLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getClients() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/clients').then(response => {
                this.isLoading = false
                this.clients = response.data.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        switchToDevis() {
            this.form.status = 'Devis'
            this.updateReservation()
            this.createFacture()
        },
        stopreservation() {
            this.showBackground = !this.showBackground;
            this.form.status = 'Annulee'

            this.updateReservation()
        },
        closeReservation() {
            this.form.switch_caisse_at = Date()

            this.updateReservation()
        },
        createFacture() {
            this.isLoading = true

            let nom_client
            if (this.clientSelected.client_id) nom_client = this.clientSelected.nom
            else nom_client = this.clientSelected.libelle

            let FactureForm = {
                client: nom_client,
                reservation_id: this.reservationSelected.id,
                type: 'Devis',
                created_by: 'Testeur'
            }
            this.axios.post(this.baseUrl + '/api/factures/', FactureForm).then((response) => {
                if (response.data.success == true) {
                    console.log(response.data)
                    this.isLoading = false
                }
                else {
                    //afficher les erreurs coté backend
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                    //arreter le chargement
                    this.isLoading = false
                }
            })
        },
        createTable() {
            this.isLoading = true
            this.resetForm()
            this.axios.post(this.baseUrl + '/api/reservations/', this.form).then((response) => {
                if (response.data.success == true) {
                    this.select_reservation(response.data.data)
                    console.log(response.data)

                    //rafraichir la page pour ajouter l'id de la reservation
                    this.getReservations()

                    this.isLoading = false
                }
                else {
                    //afficher les erreurs coté backend
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                    //arreter le chargement
                    this.isLoading = false
                }
            })
        },
        getReservations() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservations').then((response) => {
                // Tri les réservations par id du plus grand au plus petit
                this.reservations = response.data.data
                    .sort((a, b) => b.id - a.id)
                    .map((reservation, index) => {
                        // On ajoute la clé "position" en commençant par 1
                        return {
                            ...reservation,
                            position: index + 1,
                        };
                    });
                // Sélectionne la réservation avec la dernière position (la première du tableau trié)
                if (this.reservations.length > 0) {
                    this.select_reservation(this.reservations[0])
                }
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        //on affiche ici tous les éléments qui concernent la reservation lorsqu'on clique sur une reservation
        select_reservation(reservation) {
            this.form = {}
            this.reservationSelected = reservation

            this.form = {
                caisse_id: reservation.caisse_id,
                client_id: reservation.client_id,
                nom_assure: reservation.nom_assure,
                status: reservation.status,
                secteur_assure: reservation.secteur_assure,
                caisse: reservation.caisse,
                montant: reservation.montant,
            }
            // this.selectedProduits = reservation.produits

            this.setClientLibelle()
            this.key++

        },
        addCaisseId(caisse) {
            this.form.caisse_id = caisse.id
            this.form.caisse = caisse.libelle

            this.updateReservation()
        },
        createReservation() {
            this.isLoading = true
            this.form.status = 'Terminee'
            this.form.produits = this.selectedProduits
            this.axios.post(`${this.baseUrl}/api/reservations/${this.reservationSelected.id}/update`, this.form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });

                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        resetForm() {
            this.form = {}
        },
        deleteLine(id) {
            if (!window.confirm("Etes vous sur de vouloir retirer ?")) {
                return
            }
            this.isLoading = true
            this.axios.post(this.baseUrl + '/api/reservation_produits/' + id + '/destroy').then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refreshreservation_produits').click()
                    this.refreshReservation()
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 20000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        async updateReservationProduit(line, event) {
            this.isLoading = true
            let form = {}

            if (event.target.name == 'qte') {
                form = {
                    'qte': event.target.value,
                    'cout_total': parseInt(event.target.value) * parseFloat(line.prix_de_vente)
                }
            }
            else if (event.target.name == 'prise_en_charge') {
                form = {
                    'prise_en_charge': event.target.value
                }
            }
            
            await this.axios.post(`${this.baseUrl}/api/reservation_produits/${line.id}/update`, form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.refreshReservation()
                    $('#refreshreservation_produits').click()
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        async updateReservation() {
            await this.axios.post(`${this.baseUrl}/api/reservations/${this.reservationSelected.id}/update`, this.form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        }
    }
})
</script>

<style scoped>
.card.position-relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.2);
    /* Grise l'arrière-plan */
    z-index: 1050;
    pointer-events: all;
    /* Désactive les interactions sur les éléments sous l'overlay */
}

.watermark {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(30deg);
    border-style: dashed;
    color: #ff0022;
    font-size: 2.1em;
    font-weight: bold;
    opacity: 0.5;
    z-index: 1060;
    /* z-index: 1; */
    pointer-events: none;
    /* Désactive les clics sur le filigrane */
}
</style>