<template>
  <div class="login-page">
    <div class="container d-flex justify-content-center align-items-center min-vh-100">
      <div class="card p-4" style="width: 400px;">
        <div class="card-body">
          <div class="d-flex justify-content-center">
            <img src="/img/epharma-sf.png" class="mx-auto" alt="" style="width: 150px;">
          </div>

          <h6 class="card-title text-center mb-4">
            LOGICIEL DE GESTION DE PHARMACIE
          </h6>
          <form @submit.prevent="login">
            <div class="form-group">
              <label for="name">Nom d'utilisateur</label>
              <input type="text" class="form-control" id="name" v-model="form.name" required />
            </div>
            <div class="form-group">
              <label for="password">Mot de passe</label>
              <div class="input-group">
                <input :type="passwordFieldType" class="form-control" id="password" v-model="form.password" required />
                <div class="input-group-append">
                  <button type="button" class="btn btn-outline-secondary" @click="togglePasswordVisibility">
                    <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                  </button>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary btn-block">
              Se connecter
              <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      form: {},
      passwordFieldType: 'password',
    };
  },
  mounted() {
    this.axios.post('/api/premiere-connexion').then(() => {
      this.axios.get('/sanctum/csrf-cookie')
    }).catch(() => {
      window.location.reload()
      this.$toast.add({
        severity: 'error',
        summary: 'Probleme de connexion',
        detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
        life: 5000
      });
    });
  },
  methods: {
    login() {
      this.isLoading = true
      this.axios.post('/api/login', this.form).then(response => {
        if (response.data.success === true) {
          const user = response.data.data
          this.axios.defaults.headers.common = {
            'Authorization': 'Bearer ' + user.token
          };
          this.$store.commit('setAuthenticated', user)
          this.$router.push({ name: 'home' })
          this.form = {}

        }
        else {
          this.$toast.add({
            severity: 'warn',
            detail: response.data.errors[0],
            life: 5000
          });
        }
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style scoped>
.login-page {
  background-image: url('/public/img/bg-login.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  height: 100vh;
}

.card {
  background-color: rgba(255, 255, 255, 0.9);
}
</style>