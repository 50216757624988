<template>
    <div>
        <RouterLink class="dropdown-item" :to="{name:'reservation'}">
            <div class="d-flex flex-column pt-2">
                <div class="d-flex justify-content-center"><img src="../../../public/img/icons/reservation.jpg" alt=""></div>
                <p class="font-weight-bold d-flex justify-content-center">Reservations</p>
            </div>
        </RouterLink>

        <RouterLink class="dropdown-item" :to="{name:'caisse'}">
            <div class="d-flex flex-column pt-1">
                <div class="d-flex justify-content-center"><img src="../../../public/img/icons/caisse.jpg" alt=""></div>
                <p class="font-weight-bold d-flex justify-content-center">Caisses</p>
            </div>
        </RouterLink>

        <RouterLink class="dropdown-item" :to="{name:'client'}">
            <div class="d-flex flex-column pt-1">
                <div class="d-flex justify-content-center"><img src="../../../public/img/icons/client.jpg" alt="">
                </div>
                <p class="font-weight-bold d-flex justify-content-center">Clients</p>
            </div>
        </RouterLink>

        <RouterLink class="dropdown-item" :to="{name:'devis'}">
            <div class="d-flex flex-column pt-1">
                <div class="d-flex justify-content-center"><img src="../../../public/img/icons/devis.jpg" alt="">
                </div>
                <p class="font-weight-bold d-flex justify-content-center">Devis</p>
            </div>
        </RouterLink>

        <RouterLink class="dropdown-item" :to="{name:'dashboard'}">
            <div class="d-flex flex-column pt-1">
                <div class="d-flex justify-content-center"><img src="../../../public/img/icons/vente.jpg" alt=""></div>
                <p class="font-weight-bold d-flex justify-content-center">Ventes</p>
            </div>
        </RouterLink>
        <RouterLink class="dropdown-item" :to="{name:'configuration'}">
            <div class="d-flex flex-column pt-1">
                <div class="d-flex justify-content-center"><img src="../../../public/img/icons/configuration.jpg" alt=""></div>
                <p class="font-weight-bold d-flex justify-content-center">Configurations</p>
            </div>
        </RouterLink>
    </div>
</template>
