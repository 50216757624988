<template>
    <EpharmaTemplate>
        <template #sous_menu>
            <NavStock></NavStock>
        </template>
        <template #page_title> BASE DE DONNÉES PRODUIT EPHARMA </template>
        <template #page_counter>
            <i class="fas fa-database"></i> {{ lines }}
        </template>
        <template #content>
            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines"/>
            <AsdecodeTable :key="key" :url="baseUrl + '/api/' + table" :columns="columns" :table="table" :newKeyWord="keyWord"
                v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button class="btn btn-light mr-2" @click="openCreateModal()">
                        Nouveau produit
                    </button>
                </template>
                <template #action_btns=slotProps>
                    <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import NavStock from "../NavStock";
import { defineComponent, defineAsyncComponent } from 'vue';
const CreateProduit = defineAsyncComponent(() => import('./CreateBaseDonnee.vue'));
const ShowBaseDonnee = defineAsyncComponent(() => import('./ShowBaseDonnee.vue'));
export default defineComponent({
    components: { NavStock },
    data() {
        return {
            lines: 0,
            table: 'produits',
            baseUrl: this.$store.state.api_epharma_stock,
            columns: [
                {
                    key: 'cip',
                    label: 'CIP',
                    exportable: true
                },
                {
                    key: 'libelle',
                    label: 'LIBELLÉ',
                    exportable: true
                },
                {
                    key: 'prix_achat',
                    label: "PRIX D'ACHAT",
                    exportable: true
                },
                {
                    key: 'prix_de_vente',
                    label: 'PRIX DE VENTE',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE EN STOCK',
                    exportable: true
                }
            ],
            key: 1,
            keyWord: ''
        }
    },
    
    methods: {
        
        openCreateModal() {
            this.$dialog.open(CreateProduit, {
                props: {
                    header: "Nouveau produit",
                    style: {
                        width: '80vw',
                    },
                    modal: true
                }
            });
        },
        openShowModal(objData) {
            this.$dialog.open(ShowBaseDonnee, {
                props: {
                    header: objData.libelle,
                    style: {
                        width: '80vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    }
})
</script>