<template>
    <EpharmaTemplate>
        <template #sous_menu>
            <NavVente></NavVente>
        </template>
        <template #page_title> VENTES</template>
        <template #page_counter>
            <i class="fas fa-money-bill-wave"></i>
        </template>
        <template #content>
            <div class="border shadow-lg rounded p-3 mx-auto" style="opacity: 0.7;">
                
                    <form @submit.prevent="venteFilter" class="border shadow-md rounded p-1 mb-2 "
                        style="opacity:">
                        <div class="form-row">
                            <div class="form-group col-lg">
                                <div>
                                    <small class="font-weight-bold">Rechercher par date</small>
                                    <div>
                                        <label></label>
                                        <input class="form-control form-control-3" v-model="filterForm.debut"
                                            type="date" style="padding-top: 18px; padding-bottom: 18px;
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-3">
                                <div>
                                    <small class="font-weight-bold pl-3"></small>
                                    <div>
                                        <label></label>
                                        <input class="form-control form-control-sm" v-model="filterForm.fin" type="date"
                                            style="padding-top: 18px; padding-bottom: 18px;
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-3">
                        <div>
                            <small class="font-weight-bold">Rechercher par statut</small>
                            <div>
                                <label></label>
                                <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.statut">
                                    <option value="Toutes les ventes">Toutes les ventes</option>
                                    <option value="validée">validée</option>
                                    <option value="annulée">Annulée</option>
                                </select>
                            </div>
                        </div>
                    
                        </div>
                            <div class="col-lg-2 mt-5">
                                <button type="submit" class="btn">
                                    <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                        title="Appliquer le filtrage"></i>
                                </button>
                                <button type="button" class="btn" @click="removeFilter">
                                    <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                        title="Annuler le filtrage"></i>
                                </button>
                            </div>  
                        </div>               
                </form>
                    <!-- <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par hors taxe</small>
                            <div>
                                <label></label>
                                <input class="form-control form-control-sm" type="text" placeholder="Rechercher" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 mt-5">
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                title="Appliquer le filtrage"></i>
                        </button>
                        <button type="button" class="btn" @click="removeFilter">
                            <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                title="Annuler le filtrage"></i>
                        </button> -->
                    <!-- </div> -->
               
            </div><br>
            <MoneyCounter :left_val="total_pa" left_label="VALEUR À L'ACHAT" :right_val="total_pv"
                right_label="VALEUR À LA VENTE" class="shadow bg-[#ccc]" /><br>

            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />

            <AsdecodeTable :key="key" :newKeyWord="keyWord" :url="baseUrl + url" :columns="columns" :table="table"
                v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button class="btn btn-light mr-2">
                        Recapitulatif CNAMGS
                    </button>
                </template>
            </AsdecodeTable>
            <p class="mt-5 mb-3 font-weight-bold">Produits vendus dans la période</p>
            <form class="border shadow-lg rounded p-3 mx-auto" style="opacity: 0.7;">
                <div class="form-row">
                    <div class="form-group col-lg-3">
                        <div>
                            <small class="font-weight-bold">Rechercher par libelle</small>
                            <div>
                                <label></label>
                                <input class="form-control form-control-sm" type="text" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" placeholder="Doliprane">
                            </div>
                        </div>
                    </div>
                </div>
            </form><br>
            <AsdecodeTable :key="key" :url="baseUrl + '/api/' + table" :columns="columns1">
                <template #header_action_btns>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import NavVente from "../NavVente";
import MoneyCounter from '@/components/MoneyCounter.vue';
export default {
    components: { NavVente, MoneyCounter },
    data() {
        return {
            lines: 0,
            table: 'ventes',
            baseUrl: this.$store.state.api_epharma_ventes,
            defaultUrl: '/api/ventes',
            url: '/api/ventes',
            columns: [
                {
                    key: 'caisse_id',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    key: 'user',
                    label: 'VENDEUSE',
                    exportable: true
                },
                {
                    key: '',
                    label: 'STATUT',
                    exportable: true
                },
                {
                    key: 'client',
                    label: 'CLIENTS',
                    exportable: true
                },
                {
                    key: 'reservation_id',
                    label: 'RESERVATION',
                    exportable: true
                },
                {
                    key: '',
                    label: 'TTC',
                    exportable: true
                },
                {
                    key: 'ht',
                    label: 'HT',
                    exportable: true
                },
                {
                    key: 'tva',
                    label: 'TVA',
                    exportable: true
                },
                {
                    key: 'css',
                    label: 'CSS',
                    exportable: true
                },
                {
                    key: 'total',
                    label: 'TOTAL CLIENT FCFA',
                    exportable: true
                },
                {
                    key: 'montant_recu',
                    label: 'TOTAL PRISE EN CHARGE FCFA',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'STATUT',
                    exportable: true
                },
                {
                    key: '',
                    label: 'ACTIONS',
                    exportable: true
                },

            ],
            key: 1,
            keyWord: '',
            filterForm: {},
            columns1: [
                {
                    key: '',
                    label: 'CIP',
                    exportable: true
                },
                {
                    key: '',
                    label: 'LIBELLE',
                    exportable: true
                },
                {
                    key: '',
                    label: 'STATUT',
                    exportable: true
                },
                {
                    key: '',
                    label: 'QUANTITE VENDU',
                    exportable: true
                },
                {
                    key: '',
                    label: 'STOCK ACTUEL',
                    exportable: true
                },
                {
                    key: '',
                    label: 'VENTE',
                    exportable: true
                },
            ]

        }
    },
    mounted() {
        this.getTotalAssurance()
        this.getTotalChiffreAffaire()
    },
    methods: {
        getTotalAssurance() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?column_sum=prix_achat').then(response => {
                this.isLoading = false
                this.total_pa = response.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getTotalChiffreAffaire() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?column_sum=prix_de_vente').then(response => {
                this.isLoading = false
                this.total_pv = response.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        },
        checkUrlFormat(link){
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        venteFilter(){
            let newUrl = this.defaultUrl
            // if (this.filterForm.type) {
            //     newUrl = this.checkUrlFormat(newUrl) + 'type=' + this.filterForm.type
            // }

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut='+this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) +'from_period_fin='+this.filterForm.fin
            }
            if (this.filterForm.statut) {
                newUrl = this.checkUrlFormat(newUrl) + 'statut=' + this.filterForm.statut
            }

            this.url = newUrl
            this.key++
        },
        removeFilter() {
            this.url = this.defaultUrl
            this.key++
        },
    }
}


</script>