<template>
        <div id="sousmenuestock">
            <RouterLink  :to="{name:'produit'}" class="dropdown-item">
                <div class="d-flex flex-column pt-2">
                    <div class="d-flex justify-content-center"><img src="../../../public/img/icons/produits.jpg" alt=""></div>
                    <p class="font-weight-bold d-flex justify-content-center">Produits</p>
                </div>
            </RouterLink>

            <RouterLink :to="{name:'commande'}" class="dropdown-item" >
                <div class="d-flex flex-column pt-1">
                    <div class="d-flex justify-content-center"><img src="../../../public/img/icons/commandes.jpg" alt=""></div>
                    <p class="font-weight-bold d-flex justify-content-center">Commandes</p>
                </div>
            </RouterLink>

            <RouterLink class="dropdown-item" :to="{name:'fournisseurs'}">
                <div class="d-flex flex-column pt-1">
                    <div class="d-flex justify-content-center"><img src="../../../public/img/icons/fournisseurs.jpg" alt="">
                    </div>
                    <p class="font-weight-bold d-flex justify-content-center">Fournisseurs</p>
                </div>
            </RouterLink>

            <RouterLink class="dropdown-item" :to="{name:'database'}">
                <div class="d-flex flex-column pt-1">
                    <div class="d-flex justify-content-center"><img src="../../../public/img/icons/bases_de_donnees.jpg" alt="">
                    </div>
                    <p class="font-weight-bold d-flex justify-content-center">Bases de données</p>
                </div>
            </RouterLink>

            <!-- <RouterLink class="dropdown-item" :to="{name:'rayon'}">
                <div class="d-flex flex-column pt-1">
                    <div class="d-flex justify-content-center"><img src="../../../public/img/icons/rayon.jpg" alt=""></div>
                    <p class="font-weight-bold d-flex justify-content-center">Rayon</p>
                </div>
            </RouterLink> -->

            <!-- <RouterLink class="dropdown-item" :to="{name:'mouvement'}">
                <div class="d-flex flex-column pt-1">
                    <div class="d-flex justify-content-center"><img src="../../../public/img/icons/mouvements.jpg" alt=""></div>
                    <p class="font-weight-bold d-flex justify-content-center">Mouvement</p>
                </div>
            </RouterLink> -->
        </div>
</template>
