<template>
    <EpharmaTemplate>
          <template #sous_menu>
                <NavGestion></NavGestion>
          </template>
          <template #content>
                <DefaultView></DefaultView>
          </template>
    </EpharmaTemplate>
</template>

<script>
import NavGestion from "./NavGestion";
import DefaultView from "@/components/DefaultView";
export default {
    components: { NavGestion, DefaultView }
}
</script>