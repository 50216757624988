<template>
    <EpharmaTemplate>
        <template #page_title> ARRÊTÉ DE CAISSE </template>
        <template #page_counter>
            <i class="fa-solid fa-money-bill"></i> {{ lines }}
        </template>
        <template #content>
            <form class="border shadow rounded p-3 mx-auto mb-3" style="opacity: 0.7;">
                <div class="form-row">
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par date</small>
                            <div>
                                <label></label>
                                <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold pl-3"></small>
                            <div>
                                <label></label>
                                <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par mots clés</small>
                            <div>
                                <label></label>
                                <input class="form-control form-control-sm" type="text" placeholder="Rechercher" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                            </div>
                        </div>
                    </div>
                    <div class="col-4 mt-5">
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                title="Appliquer le filtrage"></i>
                        </button>
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                title="Annuler le filtrage"></i>
                        </button>
                    </div>
                </div>
            </form>
            <AsdecodeTable :key="key" :url="baseUrl + '/api/' + table" :columns="columns" :table="table"
                :newKeyWord="keyWord" v-on:set-total-records="updateLines">
                <template #action_btns=slotProps>
                    <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        <i class="fa-solid fa-folder-open"></i>
                    </button>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>

</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    data() {
        return {
            lines: 0,
            table: 'billetages',
            baseUrl: this.$store.state.api_epharma_ventes,
            key: 1,
            keyWord: '',
            columns: [
                {
                    key: 'caisse_libelle',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    key: 'ended_with',
                    label: 'CLÔTURÉ PAR',
                    exportable: true
                },
                {
                    key: 'total_vente',
                    label: 'TOTAL VENTE',
                    exportable: true
                },
                {
                    key: 'total_billetage',
                    label: 'TOTAL BILLETAGE',
                    exportable: true
                },
                {
                    key: 'ecart',
                    label: 'ECART',
                    exportable: true
                },
                {
                    key: 'adresse',
                    label: 'PÉRIODE',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'STATUT',
                    format: 'state',
                    exportable: true
                }
            ]
        }

    }

})
</script>
