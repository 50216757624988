<template>
    <EpharmaTemplate>
        <template #page_title> PRODUITS </template>
        <template #page_counter>
            <i class="fas fa-box"></i> {{ lines }}
        </template>
        <template #content>
            <button hidden id="refreshcounter" type="button" @click="refreshCounters">Raffraichir le compteur</button>
            <MoneyCounter :key="key" :left_val="total_pa" left_label="VALEUR À L'ACHAT" :right_val="total_pv"
                right_label="VALEUR À LA VENTE" /><br>
            <div class="row border shadow-md rounded p-1 mb-2 mx-auto d-flex align-items-center">
                <div class="col-md-6">
                    <form @submit.prevent="produitFilter">
                <div class="form-row">
                    <div class="col-md">
                        <p class="mb-3"><small>Rechercher par rayon</small></p>
                        <select class="form-control form-control-sm" v-model="filterForm.rayon_id">
                                    <option v-for="rayon in rayons" :key="rayon.id" :value="rayon.id">{{ rayon.libelle }}</option>
                                </select>
                    </div>
                    <div class="col-md">
                        <p class="mb-3"><small>Rechercher par nature</small></p>
                        <select class="form-control form-control-sm" v-model="filterForm.nature">
                                    <option v-for="(nature, index) in naturesList" :key="index" :value="nature">{{
                                        nature }}</option>
                                </select>
                    </div>
                    <div class="col-md-3">
                        <button class="btn" type="submit">
                            <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                title="Appliquer le filtrage"></i>
                        </button>
                        <button type="button" class="btn" @click="removeFilter">
                            <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                title="Annuler le filtrage"></i>
                        </button>
                    </div>
                </div>
            </form>
                </div>
                <div class="col-md-6">
                    <p class="mb-3"><small>Rechercher par mot clé</small></p>
                    <searchByKeyword v-on:set-keyword-filter="searchLines" />
                </div>
            </div>
            
            <AsdecodeTable :key="key" :newKeyWord="keyWord" :url="baseUrl + url" :columns="columns" :table="table"
                v-on:set-total-records="updateLines">
                <li v-for="column in filteredItems" :key="column">{{ colomn }}</li>
                <template #action_btns=slotProps>
                    <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        <i class="fa-solid fa-folder-open"></i>
                    </button>
                </template>
                <template #qte=slotProps>
                    <span v-if="parseInt(slotProps.data.qte) >= parseInt(slotProps.data.qte_max)" class="rounded-pill badge badge-primary p-2" >
                        {{ slotProps.data.qte }}
                    </span>
                    <span v-else-if="parseInt(slotProps.data.qte ) > parseInt(slotProps.data.qte_min)" class="rounded-pill badge badge-secondary p-2" >
                        {{ slotProps.data.qte }}
                    </span>
                    <span v-else-if="parseInt(slotProps.data.qte) <= parseInt(slotProps.data.qte_min)" class="rounded-pill badge badge-warning border border-dark p-2" >
                        {{ slotProps.data.qte }}
                    </span>
                </template>
                <template #libelle=slotProps>
                    {{ slotProps.data.libelle }} 
                    <span class="text-vert ml-2" v-if="slotProps.data.tva == '1'">TVA</span>
                    <span class="text-bleu3 ml-2" v-if="slotProps.data.css == '1'">CSS</span>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { natures } from '../bases_de_donnees/resources/natures.js';
import MoneyCounter from '@/components/MoneyCounter.vue';
const EditProduit = defineAsyncComponent(() => import('./EditProduit.vue'));
export default defineComponent({
    components: { MoneyCounter },
    data() {
        return {
            lines: 0,
            isLoading: false,
            table: 'produits',
            baseUrl: this.$store.state.api_epharma_stock,
            defaultUrl: '/api/produits',
            url: '/api/produits',
            baseUrl: this.$store.state.api_epharma_stock,
            columns: [
                {
                    key: 'libelle',
                    label: 'LIBELLE',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'rayon.libelle',
                    label: 'RAYON',
                    exportable: true
                },
                {
                    key: 'nature',
                    label: 'NATURE',
                    exportable: true
                },
                {
                    key: 'prix_achat',
                    label: 'PRIX ACHAT',
                    exportable: true,
                    format: 'money'
                },
                {
                    key: 'prix_de_vente',
                    label: 'PRIX VENTE',
                    exportable: true,
                    format: 'money'
                },
            ],
            total_pa: 0,
            total_pv: 0,
            key: 1,
            keyWord: '',
            filterForm: {},
            rayons: [],
            naturesList: [],
           
        }
    },
    created() {
        this.getRayons()
        this.naturesList = natures;
    },
    mounted() {
        this.refreshCounters()
    },
    methods: {
        async refreshCounters(){
            await this.getPrixAchatSum()
            await this.getPrixVenteSum()
        },
        produitFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.rayon_id && this.filterForm.nature) {
                newUrl = newUrl + '?rayon_id=' + this.filterForm.rayon_id + '&nature=' + this.filterForm.nature
            }

            else if (this.filterForm.rayon_id) {
                newUrl = newUrl + '?rayon_id=' + this.filterForm.rayon_id
            }

            else if (this.filterForm.nature) {
                newUrl = newUrl + '?nature=' + this.filterForm.nature
            }

            this.url = newUrl
            this.key++
        },
        removeFilter() {
            this.url = this.defaultUrl
            this.key++
        },
        getPrixAchatSum() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?column_sum=prix_achat').then(response => {
                this.isLoading = false
                this.total_pa = response.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getPrixVenteSum() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?column_sum=prix_de_vente').then(response => {
                this.isLoading = false
                this.total_pv = response.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getRayons() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/rayons/').then(response => {
                this.rayons = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        openShowModal(objData) {
            this.$dialog.open(EditProduit, {
                props: {
                    header: objData.libelle,
                    style: {
                        width: '80vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    }

})
</script>
