<template>
    <RouterLink class="dropdown-item" :to="{ name: 'utilisateurs' }">
         <i class="fas fa-users"></i>
        <span>Utilisateurs</span>
    </RouterLink>

    <RouterLink :to="{ name: 'parametres' }" class="dropdown-item">
         <i class="fas fa-cogs"></i>
        <span>Parametres</span>
    </RouterLink>
</template>

<style scoped>
.nav-icon-text {
    text-align: center;
    display: block;
}

.nav-icon-text .icon {
    display: block;
    margin-bottom: 5px;
}

.nav-link{
    padding: 3 !important;
}
</style>