
<template>
        <EpharmaTemplate>
          <template #sous_menu>
                <NavVente></NavVente>
          </template>
          <template #page_title>STATISTIQUES</template>
        <template #page_counter>
          <i class="fa-solid fa-chart-simple" style="font-size: 25px;"></i>
        </template>
        <template  #content>
            <p class="text-center h4">Evolution du chiffre d'affaire</p><br>
            <form class="mb-5" >
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 mx-auto">
                            <label for="">Périodicité</label>
                            <select class="form-control" v-model="TypePeriode" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                <option value="MENSUEL">MENSUEL</option>
                                <option value="JOURNALIERE">JOURNALIERE</option>
                                <option value="HEBDOMADAIRE">HEBDOMADAIRE</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
            <div class="shadow-lg">
            <p class="font-weight-bold text-center" style="color: #0b508c;">CHIFFRE D'AFFAIRE {{ TypePeriode }}</p>
            <form class="mt-4  p-3">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto d-flex justify-content-between">
                            <div class="col-md-4">
                                <input type="number" value="2024" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                            </div>
                            <div class="col-md-4">
                                 <button class="text-white p-1" style="background-color: #153b82; border: none;">Appliquer</button>
                             </div>
                        </div>
                    
                    </div>
                </div>
            </form>
             <Chart type="bar" :data="chartData" :options="chartOptions" class="h-30rem"  />
             </div>
             <p class="text-center h4" style="margin-top: 5%;">Statistique sur les produits</p><br>
             <div class="container-fluid">
                <div class="row">
                    <div class="col-lg">
                        <div class="card flex justify-content-center" style="margin-top: 2%;">
                             <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="card flex justify-content-center" style="margin-top: 2%;">
                             <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="card flex justify-content-center" style="margin-top: 2%;">
                             <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                </div>
             </div>
             <div class="container-fluid mt-5">
                <div class="row">
                    <div class="col-lg">
                        <div class="card flex justify-content-center" style="margin-top: 2%;">
                             <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="card flex justify-content-center" style="margin-top: 2%;">
                             <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="card flex justify-content-center" style="margin-top: 2%;">
                             <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                </div>
             </div>
        </template>
    </EpharmaTemplate>
   
</template>

<script>

import Chart from 'primevue/chart';

export default {
    components:{Chart},
    data() {
        return {
            chartData: null,
            chartOptions: null,
            TypePeriode:'MENSUEL'
        };
    },
    mounted() {
        this.chartData = this.setChartData();
        this.chartOptions = this.setChartOptions();
    },
    methods: {
        setChartData() {
            const documentStyle = getComputedStyle(document.documentElement);

            return {
                labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'],
                datasets: [
                    {
                        label: "Premier chiffre d'affaire",
                        data: [540, 325, 702],
                        hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')],
                        backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
                        borderColor: documentStyle.getPropertyValue('--cyan-500'),
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: "Second chifre d'affaire",
                        backgroundColor: documentStyle.getPropertyValue('--gray-500'),
                        borderColor: documentStyle.getPropertyValue('--gray-500'),
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            usePointStyle: true
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }
                }
            };
        }
    }
};
</script>
