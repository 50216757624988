<template>
    <EpharmaTemplate>
        <template #sous_menu>
            <NavGestion></NavGestion>
        </template>
        <template #page_title> FIN DE JOURNÉE </template>
        <template #page_counter>
            <i class="fa-solid fa-money-bill"></i> 10
        </template>
        <template #content>
            <AsdecodeTable :columns="columns">
            </AsdecodeTable><br><br>
            <AsdecodeTable :columns="columns1">
                <template #header_action_btns>
                    <button style="border:none">
                        Recapitulatif CNAMGS
                    </button>
                </template>
            </AsdecodeTable><br><br>
            <p style="font-weight: bold;">Produits vendus dans la période</p>
            <AsdecodeTable :columns="columns2" style="margin-bottom: 5%;">
                <template #action_btns=slotProps>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        </button>
                        <button class="text-white" style="background: #008000; border-color: #008000;">
                            Trouver les ventes
                            <i class="fas fa-sign-in-alt"></i>
                        </button>
                    </div>
                </template>
            </AsdecodeTable>
            <searchByKeyword class="mb-3"  v-on:set-keyword-filter="searchLines" /><br>
            <form class="border shadow-lg rounded p-3 mx-auto" style="opacity: 0.7;">
                <div class="form-row">
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par motif</small>
                            <div>
                                <label></label>
                              <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                <option value="Vide">Vide</option>
                              </select>
                           </div>
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                               <small class="font-weight-bold pl-3">Rechercher par date</small>
                                <div>
                                    <label></label>
                                    <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                               </div>   
                        </div>
                    </div>
                    <div class="form-group col-lg">
                        <div>
                               <small class="font-weight-bold pl-3"></small>
                                <div>
                                    <label></label>
                                    <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                               </div>   
                        </div>
                    </div>
                    <div class="form-group col-lg">               
                        <div>
                            <small class="font-weight-bold">Rechercher par type</small>
                            <div>
                                <label></label>
                               <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                <option value="Entrée">Entrée</option>
                                <option value="Sortie">Sortie</option>
                               </select>
                            </div>  
                        </div>
                   </div>
                   <div class="col-lg-4 mt-5">
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;" title="Appliquer le filtrage"></i>
                       </button>
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;" title="Annuler le filtrage"></i>
                        </button>
                   </div>
                </div>
            </form><br>
            <AsdecodeTable :key="key" :newKeyWord="keyWord" :columns="columns3" >
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>

</template>

<script>
import NavGestion from "../NavGestion";
import { defineComponent } from 'vue';
import AsdecodeTable from "../../../components/AsdecodeTable.vue"
export default defineComponent({
    components: { NavGestion, AsdecodeTable },
    data() {
        return {
            columns: [
                {
                    key: '',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    // key: 'name',
                    label: 'CLÔTURÉ PAR',
                    exportable: true
                },
                {
                    // key: 'telephone',
                    label: 'TOTAL VENTE',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'TOTAL BILLETAGE',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'ECART',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'PÉRIODE',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'STATUT',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'ACTION',
                    exportable: true
                }

            ],

            columns1: [
                {
                    key: '',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    // key: 'name',
                    label: 'VENDEUSE',
                    exportable: true
                },
                {
                    // key: 'telephone',
                    label: 'STATUS',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'CLIENT',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'RESERVATION',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'TTC',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'HT',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'TVA',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'CSS',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'TOTAL CLIENT FCFA',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'TOTAL PRIS EN CHARGE FCFA',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'ACTIONS',
                    exportable: true
                }

            ],

            columns2: [
                {
                    key: '',
                    label: 'CIP',
                    exportable: true
                },
                {
                    // key: 'name',
                    label: 'LIBELLE',
                    exportable: true
                },
                {
                    // key: 'telephone',
                    label: 'QUANTITE VENDU',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'STOCK ACTUEL',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'VENTES',
                    exportable: true
                },
            ],

            
            columns3: [
                {
                    key: '',
                    label: 'PRODUIT',
                    exportable: true
                },
                {
                    // key: 'name',
                    label: 'CATEGORIE',
                    exportable: true
                },
                {
                    // key: 'telephone',
                    label: 'QUANTITE',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'DATE',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'MOTIF',
                    exportable: true
                },
                {
                    // key: 'adresse',
                    label: 'UTILISATEUR',
                    exportable: true
                }
            ],
            key: 1,
            keyWord: ''

        }

    },
    methods:{
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    }

})
</script>