<template>
    <EpharmaTemplate>
          <template #sous_menu>
                <NavVente></NavVente>
          </template>
          <template #page_title>CONFIGURATION</template>
        <template #page_counter>
          <i class="fas fa-cash-register" style="font-size: 25px;"></i> {{ lines }}
        </template>
        <template #content>
            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
            <AsdecodeTable  :key="key"  :url="baseUrl + '/api/' + table" :columns="columns" :table="table" :newKeyWord="keyWord" v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button class="btn btn-light mr-2"  @click="openCreateModal()">
                     Nouvelle caisse
                    </button>
                </template>
                <template #action_btns=slotProps>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </button>
                        <!-- <RouterLink :to="{name:'numero'}"><button class="text-white" style="background: #008000; border-color: #008000;">
                            Connexion à la caisse
                            <i class="fas fa-sign-in-alt"></i>
                        </button></RouterLink>  -->
                    </div>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
  import NavVente from "../NavVente";
  import { defineComponent, defineAsyncComponent } from 'vue';
  const CreateConfiguration = defineAsyncComponent(() => import('./CreateConfiguration.vue'));
  const EditConfiguration = defineAsyncComponent(() => import('./EditConfiguration.vue'));
  const ShowConfiguration = defineAsyncComponent(() => import('./ShowConfiguration.vue'));
  export default defineComponent({
      components:{ NavVente},
      data() {
        return {
           lines: 0,
           table: 'caisses',
           baseUrl: this.$store.state.api_epharma_ventes,
            columns: [
                {
                    key: 'libelle',
                    label: 'LIBELLE',
                    exportable: true
                },
                {
                    key: '',
                    label: 'DERNIER UTILISATEUR CONNECTÉ',
                    exportable: true
                },
                {
                    key: '',
                    label: 'DERNIERE CONNEXION',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'STATUT',
                    exportable: true
                },
                {
                    key: 'created_by',
                    label: 'CREER PAR',
                    exportable: true
                }
            ],
            key: 1,
            keyWord: ''
        }
    },
    methods: {
          openCreateModal() {
              this.$dialog.open(CreateConfiguration, {
                  props: {
                      header: "Nouvelle caisse",
                      style: {
                          width: '80vw',
                      },
                      modal: true
                  }
              });
          },
          openCreateModalEditConfiguration() {
              this.$dialog.open(EditConfiguration,{
                  props: {
                      header: "Caisse libelle",
                      style: {
                          width: '60vw',
                      },
                      modal: true
                  }
              });
          },
          openShowModal(objData) {
            this.$dialog.open(ShowConfiguration, {
                props: {
                    header: "caisse N°"+objData.id,
                    style: {
                        width: '60vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
      }
    })
</script>