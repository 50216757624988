<template>
    <EpharmaTemplate>
        <template #page_title> COMMANDES </template>
        <template #page_counter>
            <i class="fas fa-shipping-fast"></i> {{ lines }}
        </template>
        <template #content>
            <div class="row border shadow-md rounded p-1 mb-2 mx-auto d-flex align-items-center">
                <div class="col-md-6">
                    <p class="mb-3"><small>Rechercher par date</small></p>
                    <form @submit.prevent="periodFilter" class="form-row">
                        <div class="col-md">
                            <input class="form-control form-control-sm"  type="date" v-model="filterForm.debut" >
                        </div>
                        <div class="col-md">
                            <input class="form-control form-control-sm "type="date"  v-model="filterForm.fin" >
                        </div>
                        <div class="col-md-3">
                            <button type="submit" class="btn">
                                <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                    title="Appliquer le filtrage"></i>
                            </button>
                            <button type="button" class="btn" @click="removeFilter">
                                <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                    title="Annuler le filtrage"></i>
                            </button>
                        </div>
                    </form>
                </div>

                <div class="col-md-6">
                    <p class="mb-3"><small>Rechercher par mot clé</small></p>
                    <searchByKeyword v-on:set-keyword-filter="searchLines" />
                </div>
            </div>

            <AsdecodeTable 
            :key="key" :newKeyWord="keyWord" :url="baseUrl + url" :columns="columns" :table="table"
                v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button class="btn btn-light btn-sm mr-2" @click="openCreateModal()">
                        <i class="fa-solid fa-plus"></i> Nouvelle commande
                    </button>
                </template>
                <template #action_btns=slotProps>
                    <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        <i class="fa-solid fa-folder-open"></i>
                    </button>
                </template>
                <template #status=slotProps>
                    <span class="statut " :class="[slotProps.data.status == 'SUCCESS' ? 'termine' : 'encours']">{{ slotProps.data.status }}</span>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
const CreateCommande = defineAsyncComponent(() => import('./CreateCommande.vue'));
const ShowCommande = defineAsyncComponent(() => import('./ShowCommande.vue'));

export default defineComponent({
    data() {
        return {
            lines: 0,
            table: 'commandes',
            baseUrl: this.$store.state.api_epharma_stock,
            defaultUrl: '/api/commandes',
            url: '/api/commandes',
            columns: [
                {
                    key: 'numero',
                    label: 'Numero',
                    exportable: true
                },
                {
                    key: 'created_at',
                    label: 'Date de création',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'fournisseur_libelle',
                    label: 'Fournisseur',
                    exportable: true
                },
                {
                    key: 'status',
                    label: 'Statut',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'total_achat_cfa',
                    label: 'Prix d\'achat ',
                    format: 'money',
                    exportable: true
                },
            ],
            key: 1,
            keyWord: '',
            filterForm: {},
        }
    },
    methods: {
        periodFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.debut && this.filterForm.fin) {
                newUrl = newUrl + '?from_period_debut=' + this.filterForm.debut + '&from_period_fin=' + this.filterForm.fin
            }

            else if (this.filterForm.debut) {
                newUrl = newUrl + '?from_period_debut=' + this.filterForm.debut
            }

            else if (this.filterForm.fin) {
                newUrl = newUrl + '?from_period_fin=' + this.filterForm.fin
            }

            this.url = newUrl
            this.key++
        },
        removeFilter() {
            this.url = this.defaultUrl
            this.key++
        },
        openCreateModal() {
            if (!window.confirm("Etes vous sur de vouloir créer une nouvelle commande ? Cette action est irréversible !")) {
                return
            }
            this.$dialog.open(CreateCommande, {
                props: {
                    header: "Nouvelle commande",
                    style: {
                        width: '90vw',
                    },
                    modal: true
                }
            });
        },
        openShowModal(objData) {
            this.$dialog.open(ShowCommande, {
                props: {
                    header: "Commande N°" + objData.id,
                    style: {
                        width: '90vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    },
})
</script>

<style>
.statut {
    border: 1px solid red;
    color: red;
    background: rgb(255, 250, 250);
    padding: .2rem 1rem;
    border-radius: 100px;
}

.encours {
    border: 1px solid darkblue;
    color: #00008b;
    background: rgb(236, 236, 255);
    font-weight: 500;
    padding: .2rem 1rem;
    border-radius: 100px;
}

.termine {
    border: 1px solid green;
    color: green;
    background: rgb(249, 255, 249);
    font-weight: 700;
    padding: .2rem 1rem;
    border-radius: 100px;
}

</style>