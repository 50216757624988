<template>
    <div class="container" style="max-width: 900px; min-width: 900px;">
        <p class="text-bleu h1 font-weight-bold d-flex justify-content-center mt-3">Pharmacie Test Routeur</p>
        <div class=" d-flex justify-content-center"><img src="/img/icons/epharma.png" alt="" id="imglogo2epharma"></div>

        <div class="card mb-3 gradient-color">
            <div class="card-body p-4 row text-white">
                <div class="col-md-6 mb-2">
                    <span class="text-uppercase font-weight-light pb-2">Telephone</span><br>
                    <strong class="font-weight-bold">062000000</strong>
                </div>
                <div class="col-md-6 mb-2">
                    <span class="text-uppercase font-weight-light pb-2">Email</span><br>
                    <strong class="font-weight-bold">pharmacie.testrouteur@gmail.com</strong>
                </div>
                <div class="col-md-6 mb-2">
                    <span class="text-uppercase font-weight-light pb-2">Adresse</span><br>
                    <strong class="font-weight-bold">Centre-Ville, Libreville Gabon</strong>
                </div>
                <div class="col-md-6 mb-2">
                    <span class="text-uppercase font-weight-light pb-2">Information</span><br>
                    <strong class="font-weight-bold">SING Post Epharma</strong>
                </div>
            </div>
        </div>
        <div class="bg-gris card card-body ">
            <p>Ce logiciel de gestion de pharmacie vous est fournis par E-pharma. Nous mettons à la disposition des
                pharmacies les outils numériques nécessaire pour augmenter leur éfficacité au quotidien. Pour toutes vos
                questions n'hésitez pas à nous contacter au numéro suivant:</p>
            <div class="text-primary pl-4" id="bordercontact">
                <span class="font-weight-bold">CONTACT</span><br>
                <strong>062 38 74 01</strong><br>
                <em>E-Pharma, Coworkging SING SA Bureau 2,Rue-Pecqueur, Centre-Ville, Libreville Gabon</em>
            </div>
        </div>
    </div>
</template>

<style scoped>
.gradient-color {
    width: 100%;
    background: linear-gradient(to bottom, #12a9d2 0%, #3050db 100%)
}
</style>