<template>
      <EpharmaTemplate>
            <template #sous_menu>
                  <NavStock></NavStock>
            </template>
            <template #content>
                  <!-- <button type="button" class="btn btn-primary" @click="openCreateModal()">Primary</button> -->
                  <DefaultView></DefaultView>
            </template>
      </EpharmaTemplate>
</template>

<script>
import { defineComponent } from "vue";
import NavStock from "./NavStock";
import DefaultView from "@/components/DefaultView";
export default defineComponent({
      components: { NavStock, DefaultView },

      
     
})
</script>