<template>
      <EpharmaTemplate>
            <template #sous_menu>
                  <NavAdmin></NavAdmin>
                  <AlertBanner></AlertBanner>
                  
            </template>
            <template #content>
                  <DefaultView></DefaultView>
            </template>
      </EpharmaTemplate>
</template>
 
<script>
import NavAdmin from "./NavAdmin";
import { defineComponent, defineAsyncComponent } from 'vue';
import DefaultView from "@/components/DefaultView";
import AlertBanner from "@/layouts/AlertBanner.vue";
const CreateUtilisateur = defineAsyncComponent(() => import ('@/views/admin/utilisateurs/CreateUtilisateur.vue'));
export default defineComponent({
      components: { NavAdmin, DefaultView , AlertBanner },
      methods: {
            openCreateModal() {
                  this.$dialog.open(CreateUtilisateur, {
                        props: {
                              header: "Nouvelle utilisateur",
                              style: {
                                    width: '60vw'    
                              },
                              modal: true
                        }
                  });
                  
            },
      }
})
</script>

<style scoped>
.custom-modal-header .p-dialog-header {
  /* text-: #ff5733; Changez cette couleur à la couleur désirée */
  color: 'bleu2'; /* Changez cette couleur à la couleur désirée */
}
</style>
